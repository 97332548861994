<template>
  <Loader :loader_flag="loader_flag" />
  <div v-click-away="onClickAway">
    <button
      type="button"
      :class="usergroup === '3' ? 'img_upload_btn_em' : 'img_upload_btn'"
      class="
        bg-white
        border border-solid border-gray-200
        px-4
        py-2
        rounded-full
      "
      v-on:click="selectOptionBoxOpen"
    >
      <IconGenerator
        :iconName="iconAttrs.iconName"
        :iconClasses="iconAttrs.iconClasses"
      />
      <span class="text-sm font-medium text-gray-700"></span>
    </button>
    <div class="selectPhotoOptionList" v-show="selectOptionBox">
      <button
        class="main_button m-auto mb-2 w-100"
        v-on:click="$refs.inputRef.click()"
      >
        <span>Select a photo</span>
      </button>
      <button
        class="main_button m-auto w-100"
        v-on:click="openCapturePhotoModal"
      >
        <span>Take a photo</span>
      </button>
    </div>
  </div>

  <input
    class="opacity-0 absolute file"
    id="file"
    style="width: 30px; left: 0"
    @change="updateProfile($event)"
    type="file"
    accept="image/*"
    capture="camera"
    ref="inputRef"
  />
</template>

<script>
import { mapActions } from "vuex";

// import { ref } from 'vue'
import IconGenerator from "@common/IconGenerator";

import { APIdoUpdateProfileImage } from "@/api/common/api";
import { mixin as VueClickAway } from "vue3-click-away";

import { APIdoUploadGallery } from "@/api/candidate/api";

import Loader from "@common/Loader.vue";

export default {
  props: {
    iconAttrs: {
      iconName: String,
      iconClasses: String,
    },
    usergroup: String,
    type_button: String,
  },
  data: () => ({
    loader_flag: false,
    selectOptionBox: false,
  }),
  components: {
    IconGenerator,
    Loader,
  },
  mixins: [VueClickAway],

  methods: {
    ...mapActions({
      doGetUserData: "authentication/doGetUserData",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
    }),
    onClickAway() {
      this.selectOptionBox = false;
    },
    openCapturePhotoModal() {
      const data = {
        isOpen: true,
        iconName: "CameraIcon",
        componentName: "CapturePhoto",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
    },
    selectOptionBoxOpen() {
      this.selectOptionBox = !this.selectOptionBox;
    },
    updateProfile() {
      console.log("update---");
      const form = new FormData();
      this.loader_flag = true;
      if (this.type_button === "Upload gallery") {
        const imagefile = document.querySelector("#file1");
        form.append("upload", imagefile.files[0]);
        APIdoUploadGallery(form).then((response) => {
          if (response.data.success) {
            this.$emit("updategallery");
            this.loader_flag = false;
            this.$swal({
              title: "Successfully upload",
              icon: "success",
              confirmButtonText:
                '<i class="fa fa-close"></i> Close this window',
              customClass: {
                confirmButton: "SuccessClosebutton",
              },
            });
          } else {
            this.loader_flag = false;
            this.$swal({
              title: "Oops!",
              icon: "error",
              text: response.data.message,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "ErrorClosebutton",
              },
            });
          }
        });
      } else {
        const imagefile = document.querySelector("#file");
        console.log("imagefile.files[0]", imagefile.files[0]);
        form.append("photo", imagefile.files[0]);
        this.loader_flag = true;
        APIdoUpdateProfileImage(form).then((response) => {
          if (response.data.success) {
            let userType = this.usergroup === "3" ? 3 : 2;
            this.doGetUserData(userType);
            this.loader_flag = false;
            this.$swal({
              title: "Successfully upload",
              icon: "success",
              confirmButtonText:
                '<i class="fa fa-close"></i> Close this window',
              customClass: {
                confirmButton: "SuccessClosebutton",
              },
            });
          } else {
            this.loader_flag = false;
            this.$swal({
              title: "Oops!",
              icon: "error",
              text: response.data.message,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "ErrorClosebutton",
              },
            });
          }
        });
      }
    },
  },
};
</script>
